<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="font-weight-bolder">
          {{ $t("message.chart.expenses") }}
        </b-card-title>
        <b-card-sub-title class="mt-25">
          {{ $t("message.chart.expensesDesc") }}
        </b-card-sub-title>
      </div>

      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <v-select
          v-model="period"
          :reduce="(val) => val.value"
          :options="periodOptions"
          class="ml-1"
          style="width: 250px"
          :clearable="false"
          @input="loadPeriod(period)"
        />
      </div>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        :height="chart.height"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    vSelect,
  },

  data() {
    return {
      chart: {},
      series: [],
      chartOptions: {},

      chartLabels: [],
      chartValues: [],

      chartTimestamp: "",

      userData: store.state.user.userData,

      data: [
        { name: "Valor", data: [7000, 4950, 3200, 2750, 2200, 1510] },
        {
          categories: [
            "Despesas financeiras",
            "Impostos e taxas",
            "Fretes",
            "Alimentação",
            "Marketing",
            "Salários",
          ],
        },
      ],

      period: "all",
      periodOptions: [],
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadChartData();
    },
  },

  async created() {
    await this.loadChartData();
  },

  methods: {
    loadPeriod(period) {
      if (period === "all") {
        this.period = "all";
      } else {
        this.period = period;
      }

      this.loadChartData();
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    async loadChartData() {
      var vm = this;

      await axios
        .get(`${URL_API}chart/expenses/${this.period}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          var data = JSON.parse(response.data.chartInfo); // Json to object

          if (data != null) {
            if (this.period === "all") {
              this.periodOptions = [];
              for (var i = 0; i < data[1].length; i++) {
                this.periodOptions.push({
                  label: data[1][i],
                  value: data[1][i],
                });
              }
              this.periodOptions.push({
                label: vm.getColLabel("all"),
                value: "all",
              });
            }

            var keys = Object.keys(data[0]);
            var values = Object.values(data[0]);

            this.chartLabels = keys;
            this.chartValues = values;
            this.chartTimestamp = response.data.timestamp;
          }
        });

      this.chart = {
        height: vm.getAutoHeight(vm.chartLabels.length),
      };

      this.series = [
        {
          name: vm.getColLabel("value"),
          data: this.chartValues,
        },
      ];

      this.chartOptions = {
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true, //Multicolor
            dataLabels: {
              position: "top",
            },
          },
        },

        title: {
          text: vm.localeDate(this.chartTimestamp),
          align: "right",
          margin: 10,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "normal",
          },
        },

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.3,
            opacityFrom: 0.8,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },

        legend: {
          show: false,
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: (val) => {
            return this.currencyValue(val);
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: -35,
          offsetY: 0,
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },

        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
          },
          y: {
            formatter: (value) => {
              return this.currencyValue(value);
            },
          },
        },

        xaxis: {
          categories: this.chartLabels,
          labels: {
            show: true,
            formatter: (value) => {
              return this.currencyValue(value);
            },
          },
        },
      };
    },

    getAutoHeight(value) {
      if (value === 0) {
        return 600;
      } else if (value === 1) {
        return 2 * 100;
      } else {
        return value * 120;
      }
    },

    getColLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },
  },
};
</script>
